import { graphql, useStaticQuery } from 'gatsby';
import React, { useCallback, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

import {
  Autocomplete,
  Input,
  Radio,
} from '@dogtainers/dgt-blocks/src/components/form';
import { AutocompleteItem } from '@dogtainers/dgt-blocks/src/components/form/autocomplete';
import { useIsMobile } from '@dogtainers/dgt-blocks/src/hooks/useBreakpoints';
import {
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import { POSITIVE_NUMBERS_REGEX } from '../../../common/regex';
import { DEFAULT_LOCALE } from '../../../environment';
import { Locale } from '../../../typings/i18n.types';
import { handleAutoFocus, handleFocusField, isGBLocale } from '../../utils';
import {
  FormDataQuery,
  Nodes,
  PageFormProps,
  useFormStyles,
} from '../quote.form';
import { PetBreed, PetSubtype, PetType, QuoteForm } from '../quote.types';
import { isExtraDataMandatory } from '../utils';

export const defaultLocale = DEFAULT_LOCALE as Locale;

export const allCrateTypes: AutocompleteItem[] = [
  {
    label: 'Hire',
    value: 'hire',
  },
  {
    label: 'Buy',
    value: 'buy',
  },
  {
    label: 'Own',
    value: 'own',
  },
];

const petTypesForExtraFields = ['cat', 'dog'];

const isCountryGbOrNz = (country: string) => ['GB', 'NZ'].includes(country);

export const QuotePage4: React.FC<PageFormProps> = ({
  values,
  handleChange,
  errors,
  updateForm,
  touched,
  handleBlur,
  isErrorsVisible,
  isFieldInFocus,
  children,
}) => {
  const query = graphql`
    query QuotePage4 {
      allCatsBreedJson {
        nodes {
          id
          label
        }
      }
      allDogsBreedJson {
        nodes {
          id
          label
        }
      }
      allBirdsBreedJson {
        nodes {
          id
          label
        }
      }
      allReptilesBreedJson {
        nodes {
          id
          label
        }
      }
      allGuineaPigsBreedJson {
        nodes {
          id
          label
        }
      }
      allRabbitsBreedJson {
        nodes {
          id
          label
        }
      }
      allAmphibianBreedJson {
        nodes {
          id
          label
        }
      }
      allOtherBreedJson {
        nodes {
          id
          label
        }
      }
      allPetAgesJson {
        nodes {
          id
          label
        }
      }
      allPetTypesJson {
        nodes {
          id
          label
          hasBreedsList
          isBreedRequired
          crateTypes
          subTypes
        }
      }
      allPetSubtypesJson {
        nodes {
          id
          label
          crateTypes
        }
      }
    }
  `;
  const localeCountryId = defaultLocale.slice(-2);
  const classes = useFormStyles();
  const isMobile = useIsMobile();

  const quoteType: QuoteForm['quoteType'] = values.quoteType || 'domestic';

  const crateTooltip =
    "It is important that these measurements are precise. If you're unsure, for now just enter 1 and we will get in touch for the correct measurements later. In the meantime, we will provide you a quote based on the recommended crate size for your pet.";
  const staticData = useStaticQuery<FormDataQuery>(query);
  const petTypes: AutocompleteItem[] = staticData.allPetTypesJson.nodes.map(
    (n) => ({ value: n.id, label: n.label }),
  );
  const petAges: AutocompleteItem[] = staticData.allPetAgesJson.nodes.map(
    (n) => ({ value: n.id, label: n.label }),
  );

  type PetPartial = Exclude<typeof values.pets, undefined>[number];
  const addPet = (): PetPartial => {
    const oldPets: PetPartial[] = values.pets ?? [];
    const newPet: PetPartial = { id: uuidv4() };
    updateForm({
      ...values,
      pets: [...oldPets, newPet],
    });
    return newPet;
  };

  const pets: PetPartial[] = values.pets?.length
    ? [...values.pets]
    : [addPet()];

  function deletePet(pet: PetPartial): void {
    const updatedPets = pets.filter((p) => p.id !== pet.id);
    updateForm({
      ...values,
      pets: updatedPets,
    });
  }

  const getPetType = (pet?: PetPartial): PetType | undefined => {
    return pet
      ? staticData.allPetTypesJson.nodes.find((pT) => pT.id === pet.typeId)
      : undefined;
  };

  const getPetSubtypes = (pet?: PetPartial): PetSubtype[] => {
    const petType = getPetType(pet);
    if (!petType?.subTypes?.length) {
      return [];
    }
    return staticData.allPetSubtypesJson.nodes.filter((pS) =>
      petType.subTypes.includes(pS.id),
    );
  };

  const getBreedData = (typeId: string): PetBreed[] => {
    const breedMap: Record<string, Nodes<PetBreed>> = {
      cat: staticData.allCatsBreedJson,
      dog: staticData.allDogsBreedJson,
      bird: staticData.allBirdsBreedJson,
      reptile: staticData.allReptilesBreedJson,
      'guinea-pig': staticData.allGuineaPigsBreedJson,
      rabbit: staticData.allRabbitsBreedJson,
      amphibian: staticData.allAmphibianBreedJson,
      other: staticData.allOtherBreedJson,
    };

    return breedMap[typeId]?.nodes;
  };

  const getPetBreeds = (pet?: PetPartial): AutocompleteItem[] => {
    if (!pet || !pet.typeId) {
      return [];
    }

    const breedData = getBreedData(pet.typeId);

    if (breedData) {
      return breedData.map((n) => ({
        value: n.id,
        label: n.label,
      }));
    }

    return [];
  };

  const hideHireCrateOption = useMemo(
    () =>
      quoteType === 'international' &&
      localeCountryId === 'AU' &&
      !(
        isCountryGbOrNz(values.departure?.countryId || '') ||
        isCountryGbOrNz(values.arrival?.countryId || '')
      ),
    [
      quoteType,
      localeCountryId,
      values.departure?.countryId,
      values.arrival?.countryId,
      isCountryGbOrNz,
    ],
  );

  const hideCrateOption = useMemo(
    () => quoteType === 'domestic' && isGBLocale,
    [quoteType, localeCountryId],
  );

  const getCrateTypes = useCallback(
    (pet?: PetPartial): AutocompleteItem[] => {
      if (!pet) {
        return allCrateTypes;
      }

      // Subtype filter
      if (pet.subtypeId) {
        const crateTypes =
          getPetSubtypes(pet).find((s) => s.id === pet.subtypeId)?.crateTypes ??
          [];
        if (crateTypes.length) {
          return allCrateTypes.filter(({ value }) =>
            crateTypes.includes(value),
          );
        }
      }

      // Type filter
      if (pet.typeId) {
        if (hideHireCrateOption) {
          return allCrateTypes.slice(-2);
        }

        const crateTypes = getPetType(pet)?.crateTypes ?? [];
        if (crateTypes.length) {
          return allCrateTypes.filter(({ value }) =>
            crateTypes.includes(value),
          );
        }
      }

      // Fall back to all types
      return allCrateTypes;
    },
    [allCrateTypes, getPetSubtypes, getPetType, hideHireCrateOption],
  );

  const showExtraPetData = useCallback(
    (pet: PetPartial) =>
      pet.typeId &&
      petTypesForExtraFields.includes(pet.typeId) &&
      localeCountryId !== 'AU',
    [localeCountryId, quoteType, petTypesForExtraFields],
  );

  return (
    <Grid container justifyContent="center" spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h4">Pet details</Typography>
      </Grid>
      {children}
      {pets.map((pet, idx) => (
        <React.Fragment key={pet.id}>
          {pets.length > 1 && (
            <Grid item xs={12} style={{ marginTop: '1rem' }}>
              <List dense>
                <ListItem style={{ padding: 0 }}>
                  <ListItemText>
                    <Typography variant="h5">Pet {idx + 1}</Typography>
                  </ListItemText>
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => deletePet(pet)}
                      style={{ paddingRight: 0 }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </Grid>
          )}
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Input
                  label="Pet name"
                  name={`pets[${idx}].name`}
                  onChange={handleChange}
                  value={pet?.name}
                  error={errors?.pets?.[idx]?.name}
                  touched={touched.pets?.[idx]?.name}
                  onBlur={handleBlur}
                  isErrorVisible={isErrorsVisible}
                  innerRef={isFieldInFocus?.(`pets.${idx}.name`)}
                  onFocus={(e) => handleFocusField(isMobile, e.target)}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Autocomplete
                  label="Pet type"
                  name={`pets[${idx}].typeId`}
                  menuItems={petTypes}
                  error={errors?.pets?.[idx]?.typeId}
                  value={pet?.typeId}
                  onChange={handleChange}
                  touched={touched.pets?.[idx]?.typeId}
                  isErrorVisible={isErrorsVisible}
                  onBlur={handleBlur}
                  innerRef={isFieldInFocus?.(`pets.${idx}.typeId`)}
                  onFocus={(e) => handleFocusField(isMobile, e.target)}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Input
                  regex={POSITIVE_NUMBERS_REGEX}
                  label="Pet weight"
                  name={`pets[${idx}].weight`}
                  suffix="KG"
                  onChange={handleChange}
                  value={pet?.weight}
                  error={String(errors?.pets?.[idx]?.weight ?? '')}
                  touched={touched.pets?.[idx]?.weight}
                  onBlur={handleBlur}
                  isErrorVisible={isErrorsVisible}
                  key={`${!errors?.pets?.[idx]?.typeId}_pets[${idx}].weight`}
                  autoFocus={handleAutoFocus(
                    isMobile,
                    errors?.pets?.[idx]?.typeId,
                    pet?.weight,
                  )}
                  innerRef={isFieldInFocus?.(`pets.${idx}.weight`)}
                  onFocus={(e) => handleFocusField(isMobile, e.target)}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Autocomplete
                  label="Pet age"
                  name={`pets[${idx}].ageId`}
                  menuItems={petAges}
                  error={errors?.pets?.[idx]?.ageId}
                  value={pet?.ageId}
                  onChange={handleChange}
                  touched={touched.pets?.[idx]?.ageId}
                  isErrorVisible={isErrorsVisible}
                  onBlur={handleBlur}
                  innerRef={isFieldInFocus?.(`pets.${idx}.ageId`)}
                  onFocus={(e) => handleFocusField(isMobile, e.target)}
                />
              </Grid>

              {/* Pet Breed */}
              {!!pet.typeId && (
                <Grid item xs={12} sm={6}>
                  {getPetType(pet)?.hasBreedsList ? (
                    <Autocomplete
                      label="Breed or Dominant Breed"
                      name={`pets[${idx}].breedId`}
                      menuItems={getPetBreeds(pet)}
                      error={errors?.pets?.[idx]?.breedId}
                      value={pet?.breedId}
                      onChange={handleChange}
                      touched={touched.pets?.[idx]?.breedId}
                      isErrorVisible={isErrorsVisible}
                      onBlur={handleBlur}
                      key={`${!errors?.pets?.[idx]
                        ?.ageId}_pets[${idx}].breedId`}
                      autoFocus={handleAutoFocus(
                        isMobile,
                        errors?.pets?.[idx]?.ageId,
                        pet?.breedId,
                      )}
                      innerRef={isFieldInFocus?.(`pets.${idx}.breedId`)}
                      onFocus={(e) => handleFocusField(isMobile, e.target)}
                    />
                  ) : (
                    <Input
                      label="Animal Type and Breed"
                      name={`pets[${idx}].breedOther`}
                      onChange={handleChange}
                      value={pet?.breedOther}
                      error={String(errors?.pets?.[idx]?.breedOther ?? '')}
                      touched={touched.pets?.[idx]?.breedOther}
                      onBlur={handleBlur}
                      key={`${!errors?.pets?.[idx]
                        ?.ageId}_pets[${idx}].breedOther`}
                      autoFocus={handleAutoFocus(
                        isMobile,
                        errors?.pets?.[idx]?.ageId,
                        pet?.breedOther,
                      )}
                      isErrorVisible={isErrorsVisible}
                      innerRef={isFieldInFocus?.(`pets.${idx}.breedOther`)}
                      onFocus={(e) => handleFocusField(isMobile, e.target)}
                    />
                  )}
                </Grid>
              )}

              {/* Pet Subtype */}
              {!!getPetSubtypes(pet).length && (
                <Grid item xs={12} sm={6}>
                  <Radio
                    label={`${getPetType(pet)?.label} Type`}
                    name={`pets[${idx}].subtypeId`}
                    value={pet?.subtypeId}
                    error={errors?.pets?.[idx]?.subtypeId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    menuItems={getPetSubtypes(pet).map((st) => ({
                      value: st.id,
                      label: st.label,
                    }))}
                    isErrorVisible={isErrorsVisible}
                    innerRef={isFieldInFocus?.(`pets.${idx}.subtypeId`)}
                    onFocus={(e) => handleFocusField(isMobile, e.target)}
                  />
                </Grid>
              )}
            </Grid>

            <Grid container spacing={3}>
              {showExtraPetData(pet) && (
                <>
                  <Grid item xs={12} sm={6}>
                    <Radio
                      label="Is your pet neutered?"
                      name={`pets[${idx}].neutered`}
                      value={pet?.neutered}
                      required={isExtraDataMandatory(
                        localeCountryId,
                        quoteType,
                        pet?.breedId,
                      )}
                      error={String(errors?.pets?.[idx]?.neutered ?? '')}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      menuItems={[
                        {
                          label: 'Yes',
                          value: true,
                        },
                        {
                          label: 'No',
                          value: false,
                        },
                      ]}
                      isErrorVisible={isErrorsVisible}
                      innerRef={isFieldInFocus?.(`pets.${idx}.neutered`)}
                      onFocus={(e) => handleFocusField(isMobile, e.target)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Radio
                      label="Sex"
                      name={`pets[${idx}].sex`}
                      value={pet?.sex}
                      required={false}
                      error={String(errors?.pets?.[idx]?.sex ?? '')}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      menuItems={[
                        {
                          label: 'Female',
                          value: 'female',
                        },
                        {
                          label: 'Male',
                          value: 'male',
                        },
                      ]}
                      isErrorVisible={isErrorsVisible}
                      innerRef={isFieldInFocus?.(`pets.${idx}.sex`)}
                      onFocus={(e) => handleFocusField(isMobile, e.target)}
                    />
                  </Grid>
                </>
              )}

              {!hideCrateOption &&
                !['insect', 'amphibian'].includes(`${pet?.typeId}`) &&
                pet?.typeId && (
                  <>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Radio
                        label="Crate"
                        name={`pets[${idx}].crate.type`}
                        value={pet?.crate?.type}
                        error={String(errors?.pets?.[idx]?.crate?.type ?? '')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        menuItems={getCrateTypes(pet)}
                        innerRef={isFieldInFocus?.(`pets.${idx}.crate.type`)}
                        isErrorVisible={isErrorsVisible}
                        onFocus={(e) => handleFocusField(isMobile, e.target)}
                      />
                    </Grid>
                    {pet?.crate?.type === 'own' && (
                      <>
                        <Grid item xs={12} sm={6}>
                          <Input
                            label="Crate length"
                            regex={POSITIVE_NUMBERS_REGEX}
                            name={`pets[${idx}].crate.length`}
                            suffix="CM"
                            tooltip={crateTooltip}
                            onChange={handleChange}
                            value={pet?.crate?.length}
                            error={String(
                              errors?.pets?.[idx]?.crate?.length ?? '',
                            )}
                            touched={touched.pets?.[idx]?.crate?.length}
                            onBlur={handleBlur}
                            isErrorVisible={isErrorsVisible}
                            innerRef={isFieldInFocus?.(
                              `pets.${idx}.crate.length`,
                            )}
                            onFocus={(e) =>
                              handleFocusField(isMobile, e.target)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Input
                            label="Crate height"
                            regex={POSITIVE_NUMBERS_REGEX}
                            name={`pets[${idx}].crate.height`}
                            suffix="CM"
                            tooltip={crateTooltip}
                            onChange={handleChange}
                            value={pet?.crate?.height}
                            error={String(
                              errors?.pets?.[idx]?.crate?.height ?? '',
                            )}
                            touched={touched.pets?.[idx]?.crate?.height}
                            onBlur={handleBlur}
                            isErrorVisible={isErrorsVisible}
                            innerRef={isFieldInFocus?.(
                              `pets.${idx}.crate.height`,
                            )}
                            onFocus={(e) =>
                              handleFocusField(isMobile, e.target)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Input
                            label="Crate width"
                            regex={POSITIVE_NUMBERS_REGEX}
                            name={`pets[${idx}].crate.width`}
                            suffix="CM"
                            tooltip={crateTooltip}
                            onChange={handleChange}
                            value={pet?.crate?.width}
                            error={String(
                              errors?.pets?.[idx]?.crate?.width ?? '',
                            )}
                            touched={touched.pets?.[idx]?.crate?.width}
                            onBlur={handleBlur}
                            isErrorVisible={isErrorsVisible}
                            innerRef={isFieldInFocus?.(
                              `pets.${idx}.crate.width`,
                            )}
                            onFocus={(e) =>
                              handleFocusField(isMobile, e.target)
                            }
                          />
                        </Grid>
                      </>
                    )}
                  </>
                )}
            </Grid>
          </Grid>
        </React.Fragment>
      ))}

      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Button
          className={classes.buttonWhite}
          variant="outlined"
          size="large"
          onClick={() => addPet()}
        >
          Add another pet
        </Button>
      </Grid>
    </Grid>
  );
};
