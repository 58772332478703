import React from 'react';

import {
  makeBlockStyles,
  useBlockStyles,
} from '@dogtainers/dgt-blocks/src/hooks/useStyles';
import {
  BlockBuilder,
  BlockForm,
} from '@dogtainers/dgt-blocks/src/types/_blocks.types';
import { Box, Container } from '@material-ui/core';

import { FormBooking } from '../forms/booking';
import { FormContact } from '../forms/contact';
import { OldFormContact } from '../forms/oldContact';
import { FormQuote } from '../forms/quote';
import { defaultLocale } from '../utils/language';

const useStyles = makeBlockStyles(() => ({
  root: {
    //
  },
}));

type Props = BlockBuilder<BlockForm>;

export const FormBlock: React.FC<Props> = ({ block, getImageData }) => {
  const { formId } = block;
  const classes = useBlockStyles(useStyles, block);
  function getForm(): React.FC<Props> {
    switch (formId) {
      case 'contact': {
        const localeCountryId = defaultLocale.slice(-2);
        if (localeCountryId === 'NZ') return OldFormContact;
        return FormContact;
      }
      case 'quote':
        return FormQuote;
      case 'booking':
        return FormBooking;
      default:
        throw Error(`Unknown formId "${formId}"`);
    }
  }
  const Form = getForm();
  return (
    <Box className={classes.boxBlock}>
      <Container className={classes.root}>
        {<Form block={block} getImageData={getImageData} />}
      </Container>
    </Box>
  );
};
