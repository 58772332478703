import classNames from 'classnames';
import { Link, navigate } from 'gatsby';
import React, { useState } from 'react';

import { Image } from '@dogtainers/dgt-blocks/src/components';
import {
  IconClose,
  IconHamburger,
  IconPhone,
} from '@dogtainers/dgt-blocks/src/components/icon';
import { useScreenBetween } from '@dogtainers/dgt-blocks/src/hooks/useBreakpoints';
import { normaliseUrl } from '@dogtainers/dgt-blocks/src/utils/url';
import {
  Box,
  Button,
  ButtonBase,
  Collapse,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

import { useImages } from '../../../../hooks/useImages';
import { useSiteMessage } from '../../../../hooks/useSiteMessage';
import { defaultLocale } from '../../../../utils/language';
import { localiseUrl } from '../../../../utils/language';
import { LocaleMenuMobile } from '../locale/LocaleMenuMobile';
import { MenuItem } from '../nav';

type StyleProps = {
  headerTextColour: string;
};

const useStyles = makeStyles((theme) => ({
  root: {
    color: (props: StyleProps) => props.headerTextColour,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 12,
      paddingRight: 12,
    },
  },
  iconButton: {
    color: (props: StyleProps) => props.headerTextColour,
    '& svg': {
      width: 18,
      height: 18,
    },
  },
  toolbar: {
    justifyContent: 'space-between',
    zIndex: theme.zIndex.drawer + 1,
  },
  menuItems: {
    '& > button': {
      marginLeft: '1rem',
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
      },
    },
  },
  nestedList: {
    paddingLeft: '1rem',
  },
  menuLink: {
    paddingLeft: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  link: {
    color: 'black',
    textDecoration: 'none',
    lineHeight: 1.5,
    fontSize: '1rem',
  },
  linkButton: {
    display: 'inline',
    width: 'min-content',
    marginLeft: 'auto',
  },
}));

type Props = {
  headerTextColour: string;
  menuItems: MenuItem[];
  onCall: () => void;
};

export const MobileMenu: React.FC<Props> = ({
  headerTextColour,
  menuItems,
  onCall,
}) => {
  const theme = useTheme();
  const styleProps: StyleProps = { headerTextColour };
  const classes = useStyles(styleProps);
  const isBetweenMobileAndLargeTablet = useScreenBetween('sm', 1082);
  const { getImageData } = useImages();
  const getSiteMessage = useSiteMessage();
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const shouldShowLocaleMenu = defaultLocale === 'en-AU';

  const [openMenuItems, setOpenMenuItem] = useState<Record<string, boolean>>(
    {},
  );
  const isOpenMenuItem = (id: string): boolean => !!openMenuItems[id];

  function onToggleMenu(label: string) {
    setOpenMenuItem((c) => ({
      ...c,
      [label]: !c[label],
    }));
  }

  const chooseMenuType = (item: MenuItem) =>
    item.link ? (
      <React.Fragment key={item.link}>
        <div className={classes.menuLink}>
          <Link
            to={item.link}
            className={classNames(classes.link)}
            onClick={() => {
              item.link && navigate(localiseUrl(item.link));
            }}
          >
            {getSiteMessage(item.id, item.label)}
          </Link>

          <ListItem
            button
            onClick={() => {
              onToggleMenu(item.id);
              if (!item?.menuItems.length) {
                navigate(localiseUrl(item.link));
              }
            }}
            className={classes.linkButton}
          >
            {!!item.menuItems.length &&
              (isOpenMenuItem(item.id) ? <ExpandLess /> : <ExpandMore />)}
          </ListItem>
        </div>
        {!!item.menuItems.length && SubMenu(item)}
      </React.Fragment>
    ) : (
      <React.Fragment key={item.link}>
        <ListItem
          button
          onClick={() => {
            onToggleMenu(item.id);
            if (!item?.menuItems.length) {
              navigate(localiseUrl(item.link));
            }
          }}
        >
          <ListItemText
            onClick={() => {
              item.link && navigate(localiseUrl(item.link));
            }}
            primary={getSiteMessage(item.id, item.label)}
          />
          {!!item.menuItems.length &&
            (isOpenMenuItem(item.id) ? <ExpandLess /> : <ExpandMore />)}
        </ListItem>
        {!!item.menuItems.length && SubMenu(item)}
      </React.Fragment>
    );

  const SubMenu = (item: MenuItem) => (
    <Collapse in={isOpenMenuItem(item.id)} timeout="auto" unmountOnExit>
      <List component="div" disablePadding className={classes.nestedList}>
        {item.menuItems?.map((childItem: MenuItem) => (
          <React.Fragment key={childItem.link}>
            <ListItem
              button
              onClick={() => navigate(localiseUrl(childItem.link))}
            >
              <ListItemText
                primary={getSiteMessage(childItem.id, childItem.label)}
              />
              {!!childItem.menuItems.length &&
                (isOpenMenuItem(childItem.id) ? (
                  <ExpandLess
                    onClick={(e) => {
                      e.stopPropagation();
                      onToggleMenu(childItem.id);
                    }}
                  />
                ) : (
                  <ExpandMore
                    onClick={(e) => {
                      e.stopPropagation();
                      onToggleMenu(childItem.id);
                    }}
                  />
                ))}
            </ListItem>
            {!!childItem.menuItems.length && (
              <Collapse
                in={isOpenMenuItem(childItem.id)}
                timeout="auto"
                unmountOnExit
              >
                <List
                  component="div"
                  disablePadding
                  className={classes.nestedList}
                >
                  {childItem.menuItems?.map((gChildItem: MenuItem) => (
                    <ListItem
                      key={gChildItem.link}
                      button
                      onClick={() => navigate(localiseUrl(gChildItem.link))}
                    >
                      <ListItemText
                        primary={getSiteMessage(
                          gChildItem.id,
                          gChildItem.label,
                        )}
                      />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            )}
          </React.Fragment>
        ))}
      </List>
    </Collapse>
  );

  return (
    <>
      <Toolbar className={classes.toolbar} disableGutters>
        <Link to={localiseUrl('/')}>
          <ButtonBase>
            <Image
              getImageData={getImageData}
              src={getSiteMessage('logo', 'logo_en-AU.png')}
              alt="Dogtainers Logo"
              options={
                isBetweenMobileAndLargeTablet
                  ? {
                      height: 44,
                      width: 160,
                    }
                  : {
                      height: 36,
                      width: 100,
                    }
              }
            />
          </ButtonBase>
        </Link>
        <Box>
          <IconButton aria-label="menu-phone" onClick={onCall}>
            <IconPhone
              fontSize={isBetweenMobileAndLargeTablet ? 'medium' : 'small'}
            />
          </IconButton>
          <IconButton
            aria-label="menu-hamburger"
            onClick={() => setMobileMenuOpen((c) => !c)}
          >
            <IconHamburger
              fontSize={isBetweenMobileAndLargeTablet ? 'large' : 'small'}
            />
          </IconButton>
        </Box>
      </Toolbar>
      <Drawer
        anchor="top"
        open={isMobileMenuOpen}
        onClose={() => setMobileMenuOpen(false)}
        elevation={0}
        PaperProps={{
          style: {
            backgroundColor: theme.palette.blue.light,
            height: '100%',
          },
        }}
        SlideProps={{
          direction: 'left',
        }}
      >
        <Grid container justifyContent="space-between" style={{ flexGrow: 1 }}>
          <Grid item xs={12}>
            <List>
              {/* Menu Header */}
              <ListItem style={{ padding: 0 }}>
                <ListItemText>
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <IconButton aria-label="menu-phone">
                        <IconPhone fontSize="small" />
                        &nbsp;
                        <Typography>
                          <strong>
                            {getSiteMessage('navPhone', 'Call Us')}
                          </strong>
                        </Typography>
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <IconButton
                        onClick={() => setMobileMenuOpen(false)}
                        aria-label="menu-close"
                      >
                        <IconClose fontSize="small" />
                      </IconButton>
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
              {/* Menu Items */}
              {menuItems.map(chooseMenuType)}
              {shouldShowLocaleMenu && <LocaleMenuMobile />}
            </List>
          </Grid>
          <Grid item xs={12} style={{ padding: '0 1rem' }}>
            <List>
              <ListItem style={{ padding: 0 }}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth={true}
                  href={normaliseUrl(getSiteMessage('navQuoteLink', '/quote'))}
                >
                  <Typography>
                    {getSiteMessage('navQuoteLabel', 'Get a quote')}
                  </Typography>
                </Button>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Drawer>
    </>
  );
};
