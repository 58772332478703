import React from 'react';
import { useIntl } from 'react-intl';

import { useIsDesktop } from '@dogtainers/dgt-blocks/src/hooks/useBreakpoints';

import { DesktopFooter } from './components/DesktopFooter';
import { MobileFooter } from './components/MobileFooter';
import { FooterCol } from './components/footer.utils';

type FooterArrayProps = {
  startCol: number;
  endCol: number;
  startRow: number;
  endRow: number;
};

export const Footer: React.FC = () => {
  const intl = useIntl();
  const isDesktop = useIsDesktop();
  const getSiteMessage = (id: string, fallback?: string) =>
    Object.keys(intl.messages).includes(id)
      ? String(intl.messages[id])
      : fallback;

  const footerArray = (props: FooterArrayProps): FooterCol[] => {
    const { startCol, endCol, startRow, endRow } = props;

    return [...Array(endCol - startCol + 1).keys()].map((colNum) => {
      const col: FooterCol = [];

      for (const rowNum of [...Array(endRow - startRow + 1).keys()]) {
        const label = getSiteMessage(
          `footerCol${colNum + startCol}Row${rowNum + startRow}Label`,
        );
        const link = getSiteMessage(
          `footerCol${colNum + startCol}Row${rowNum + startRow}Link`,
        );

        if (!label && label !== '') continue;
        col.push({ label, ...(link && { link }) });
      }

      return col;
    });
  };

  const desktopVerticalFooter: FooterCol[] = [
    ...footerArray({ startCol: 1, endCol: 5, startRow: 1, endRow: 9 }),
  ];

  const desktopHorizontalFooter: FooterCol[] = [
    ...footerArray({ startCol: 6, endCol: 7, startRow: 1, endRow: 5 }),
  ];

  const mobileVerticalFooter: FooterCol[] = [
    ...footerArray({ startCol: 1, endCol: 7, startRow: 1, endRow: 9 }),
  ];

  return isDesktop ? (
    <DesktopFooter
      verticalFooter={desktopVerticalFooter}
      horizontalFooter={desktopHorizontalFooter}
    />
  ) : (
    <MobileFooter footerColumns={mobileVerticalFooter} />
  );
};
