import React, { useCallback, useMemo, useState } from 'react';

import { Checkbox, Input } from '@dogtainers/dgt-blocks/src/components/form';
import { Grid } from '@material-ui/core';

import { ContactFormPartial, MediaItemsType } from '../../contact.types';
import { useStyles } from './MediaEnquiry.styles';
import Digital from './mediaTypes/Digital';
import GuestAppearance from './mediaTypes/GuestAppearance';
import PodcastRadio from './mediaTypes/PodcastRadio';
import Print from './mediaTypes/Print';
import Television from './mediaTypes/Television';

type MediaEnquiryProps = {
  values: ContactFormPartial;
  handleChange: (event: React.ChangeEvent<HTMLElement>) => void;
  errors: ContactFormPartial;
  mediaTypeItems: MediaItemsType;
  submitSection: JSX.Element;
};

const mapMediaTypeSections = {
  print: Print,
  digital: Digital,
  podcastRadio: PodcastRadio,
  televisionOpportunity: Television,
  guestAppearance: GuestAppearance,
};

const MediaEnquiry: React.FC<MediaEnquiryProps> = (props) => {
  const { values, handleChange, errors, mediaTypeItems, submitSection } = props;
  const { mediaEnquiry } = values;
  const { mediaType } = mediaEnquiry || {};

  const [selectedMediaTypes, setSelectedMediaTypes] = useState<Array<string>>(
    mediaType
      ? Object.keys(mediaType).filter(
          (media) => mediaType[media as keyof typeof mediaType],
        )
      : [],
  );

  const classes = useStyles();

  const mediaTypeItemsWithValues = useMemo(
    () =>
      mediaTypeItems.map(({ name, label }) => ({
        name: `mediaEnquiry.mediaType.${name}`,
        label: label,
        value: selectedMediaTypes.includes(name),
      })),
    [mediaTypeItems, selectedMediaTypes],
  );

  const handleChangeMediaType = useCallback(
    (e) => {
      const { name } = e.target;
      const fieldName = name.split('.').pop();

      if (selectedMediaTypes.includes(fieldName))
        setSelectedMediaTypes((prev) =>
          prev.filter((media) => media !== fieldName),
        );
      else setSelectedMediaTypes((prev) => [...prev, fieldName]);

      handleChange(e);
    },
    [handleChange, selectedMediaTypes, setSelectedMediaTypes],
  );

  return (
    <>
      <Grid item>
        <Input
          label="Company Name"
          name="mediaEnquiry.companyName"
          placeholder="Your company name here"
          onChange={handleChange}
          value={mediaEnquiry?.companyName}
          error={errors.mediaEnquiry?.companyName}
        />
      </Grid>
      <Grid item className={classes.checkboxWrapper}>
        <Checkbox
          label="Media Type (select up to 5)"
          menuItems={mediaTypeItemsWithValues}
          onChange={handleChangeMediaType}
          error={errors.mediaEnquiry?.mediaType as string}
        />
      </Grid>

      {selectedMediaTypes.map((media) => {
        const Component =
          mapMediaTypeSections[media as keyof typeof mapMediaTypeSections];
        return (
          <Component
            key={media}
            values={values.mediaEnquiry}
            handleChange={handleChange}
            errors={errors}
          />
        );
      })}
      {!!selectedMediaTypes.length && submitSection}
    </>
  );
};

export default MediaEnquiry;
