import { navigate } from 'gatsby';
import React, { useContext, useRef, useState } from 'react';

import {
  IconCaretDown,
  IconCheck,
  IconCountry,
  IconGlobe,
} from '@dogtainers/dgt-blocks/src/components/icon';
import {
  ClickAwayListener,
  Grid,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import localeSettings from '../../../../../content/settings/i18n.json';
import { LoadingContext } from '../../../../layouts/site.layout';
import { Locale } from '../../../../typings/i18n.types';
import {
  getCurrentLocale,
  getExternalDomain,
  getLocaleCountry,
  getLocaleCountryCode,
  localiseUrl,
  saveUserLocale,
} from '../../../../utils/language';

const { locales } = localeSettings;

type StyleProps = {
  headerTextColour: string;
};

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: '1rem',
  },
  iconButton: {
    color: (props: StyleProps) => props.headerTextColour,
    '& svg': {
      width: 18,
      height: 18,
    },
  },
  popper: {
    zIndex: theme.zIndex.modal,
  },
}));

export const LocaleMenu: React.FC<{
  headerTextColour: string;
}> = ({ headerTextColour }) => {
  const { setOverlay } = useContext(LoadingContext);
  const styleProps: StyleProps = { headerTextColour };
  const classes = useStyles(styleProps);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const currentLocale = getCurrentLocale();
  const currentCountryCode = getLocaleCountryCode(currentLocale as Locale);

  function setLocale(newLocale: Locale) {
    saveUserLocale(newLocale);
    setMenuOpen(false);
    const externalDomain = getExternalDomain(newLocale);
    if (externalDomain) {
      setOverlay(`Taking you to ${externalDomain} ...`);
    }
    navigate(localiseUrl(undefined, newLocale));
  }

  return (
    <>
      <IconButton
        className={classes.iconButton}
        color="inherit"
        aria-label="menu-locale"
        ref={anchorRef}
        aria-controls={isMenuOpen ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={() => setMenuOpen((c) => !c)}
      >
        <IconGlobe />
        &nbsp;
        <Typography>{currentCountryCode.toUpperCase()}</Typography>
        &nbsp;
        <IconCaretDown />
      </IconButton>
      <Popper
        className={classes.popper}
        open={isMenuOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
                <MenuList
                  autoFocusItem={isMenuOpen}
                  id="menu-list-grow"
                  onKeyDown={() => setMenuOpen(false)}
                >
                  {locales.map((locale) => (
                    <MenuItem
                      key={locale}
                      onClick={() => setLocale(locale as Locale)}
                    >
                      <Grid container spacing={2}>
                        <Grid item style={{ flexGrow: 0 }}>
                          <IconCountry locale={locale as Locale} />
                        </Grid>
                        <Grid item style={{ flexGrow: 0, minWidth: 35 }}>
                          <Typography style={{ textTransform: 'uppercase' }}>
                            {getLocaleCountryCode(locale as Locale)}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          style={{
                            flexGrow: 0,
                            paddingLeft: 0,
                            paddingRight: 0,
                          }}
                        >
                          •
                        </Grid>
                        <Grid item style={{ flexGrow: 0 }}>
                          <Typography style={{ textTransform: 'uppercase' }}>
                            {getLocaleCountry(locale as Locale)}
                          </Typography>
                        </Grid>
                        <Grid item style={{ flexGrow: 1 }}>
                          {locale === currentLocale && <IconCheck />}
                        </Grid>
                      </Grid>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
