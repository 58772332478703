import React, { useMemo } from 'react';

import {
  DatePicker,
  Input,
  Radio,
} from '@dogtainers/dgt-blocks/src/components/form';
import { useIsMobile } from '@dogtainers/dgt-blocks/src/hooks/useBreakpoints';
import { Divider, Grid, Typography } from '@material-ui/core';

import QuestionMarkSVG from '../../../images/quote/QuestionMarkSVG';
import { defaultLocale, localeCountryId } from '../../../utils/language';
import { handleFocusField } from '../../utils';
import { PageFormProps } from '../quote.form';
import { QuoteFormPartial } from '../quote.types';
import { isHiddenField } from '../quote.validation';
import { renderTooltipForAU } from '../utils';
import { requiresPetAccommodationQuestion } from '../utils/localeRendering';

export const QuotePage5: React.FC<PageFormProps> = ({
  values,
  handleChange,
  errors,
  touched,
  handleBlur,
  children,
  isFieldInFocus,
  isErrorsVisible,
}) => {
  const isMobile = useIsMobile();
  const options: QuoteFormPartial['options'] = {
    ...values.options,
  };

  const showIsExQuarantineOption = useMemo(
    () =>
      !isHiddenField(defaultLocale, 'options.isExQuarantine') &&
      values.quoteType === 'domestic' &&
      ['MEL'].includes(values.departure?.airportId as string),
    [
      defaultLocale,
      values.quoteType,
      values.departure?.airportId,
      isHiddenField,
    ],
  );

  return (
    <Grid container justifyContent="center" spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h4">Anything else you'd like to add?</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          A few final questions and your chance to tell us anything extra we
          might have missed.
        </Typography>
      </Grid>
      {children}
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Radio
              name="options.requiresPetAccommodation"
              label={requiresPetAccommodationQuestion[localeCountryId]}
              tooltip={renderTooltipForAU(
                'Dogtainers can arrange to have your pet stay at our state of the art boarding facilities.',
              )}
              value={options.requiresPetAccommodation}
              error={String(errors?.options?.requiresPetAccommodation)}
              onChange={handleChange}
              onFocus={(e) => handleFocusField(isMobile, e.target)}
              menuItems={[
                {
                  label: 'No',
                  value: false,
                },
                {
                  label: 'Yes',
                  value: true,
                },
              ]}
              innerRef={isFieldInFocus?.('options.requiresPetAccommodation')}
            />
          </Grid>
          {options.requiresPetAccommodation && (
            <Grid item xs={12} md={6}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <DatePicker
                    name="options.petAccommodation.start"
                    prefix="Start"
                    value={options?.petAccommodation?.start}
                    error={errors?.options?.petAccommodation?.start}
                    onChange={handleChange}
                    disabled={!options.requiresPetAccommodation}
                    touched={touched.options?.petAccommodation}
                    onBlur={handleBlur}
                    isErrorVisible={isErrorsVisible}
                    label="Start and end date"
                    required={false}
                    innerRef={isFieldInFocus?.(
                      'options.petAccommodation.start',
                    )}
                    onFocus={(e) => handleFocusField(isMobile, e.target)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DatePicker
                    name="options.petAccommodation.end"
                    prefix="End"
                    value={options?.petAccommodation?.end}
                    error={errors?.options?.petAccommodation?.end}
                    onChange={handleChange}
                    disabled={!options.requiresPetAccommodation}
                    touched={touched.options?.petAccommodation}
                    onBlur={handleBlur}
                    isErrorVisible={isErrorsVisible}
                    required={false}
                    innerRef={isFieldInFocus?.('options.petAccommodation.end')}
                    onFocus={(e) => handleFocusField(isMobile, e.target)}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {!isHiddenField(defaultLocale, 'options.requiresReturnFlight') && (
            <>
              <Grid item xs={12} md={6}>
                <Radio
                  name="options.requiresReturnFlight"
                  label="Do you require a return flight?"
                  tooltip={renderTooltipForAU(
                    'We will provide you with a separate return quote.',
                  )}
                  value={options.requiresReturnFlight}
                  error={String(errors?.options?.requiresReturnFlight)}
                  onChange={handleChange}
                  onFocus={(e) => handleFocusField(isMobile, e.target)}
                  menuItems={[
                    {
                      label: 'No',
                      value: false,
                    },
                    {
                      label: 'Yes',
                      value: true,
                    },
                  ]}
                  innerRef={isFieldInFocus?.('options.requiresReturnFlight')}
                />
              </Grid>
              {options.requiresReturnFlight && (
                <Grid item xs={12} md={6}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <DatePicker
                        name="options.returnFlightDate"
                        value={options?.returnFlightDate}
                        error={errors?.options?.returnFlightDate}
                        onChange={handleChange}
                        disabled={!options.requiresReturnFlight}
                        touched={touched.options?.returnFlightDate}
                        onBlur={handleBlur}
                        isErrorVisible={isErrorsVisible}
                        label="Approximate Return Travel Date"
                        required={false}
                        innerRef={isFieldInFocus?.('options.returnFlightDate')}
                        onFocus={(e) => handleFocusField(isMobile, e.target)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </>
          )}
          {showIsExQuarantineOption && (
            <>
              <Grid item xs={12} md={6}>
                <Radio
                  name="options.isExQuarantine"
                  label="Is your pet scheduled to exit Post-Entry Quarantine (PEQ)?"
                  tooltip="Cats and dogs entering Australia are required to undergo a quarantine period at Post-Entry Quarantine (PEQ) in Mickleham. If your pet has arrived from overseas, we will take care of the collection and onward transfer arrangements for you."
                  value={options.isExQuarantine}
                  error={String(errors?.options?.isExQuarantine)}
                  onChange={handleChange}
                  onFocus={(e) => handleFocusField(isMobile, e.target)}
                  menuItems={[
                    {
                      label: 'No',
                      value: false,
                    },
                    {
                      label: 'Yes',
                      value: true,
                    },
                  ]}
                  innerRef={isFieldInFocus?.('options.isExQuarantine')}
                />
              </Grid>
              {options.isExQuarantine && (
                <Grid item xs={12} md={6}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <DatePicker
                        name="options.quarantine.start"
                        prefix="Entry"
                        value={options?.quarantine?.start}
                        error={errors?.options?.quarantine?.start}
                        onChange={handleChange}
                        disabled={!options.isExQuarantine}
                        touched={touched.options?.quarantine}
                        onBlur={handleBlur}
                        isErrorVisible={isErrorsVisible}
                        label="Entry and exit date"
                        required={false}
                        innerRef={isFieldInFocus?.('options.quarantine.start')}
                        onFocus={(e) => handleFocusField(isMobile, e.target)}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <DatePicker
                        name="options.quarantine.end"
                        prefix="Exit"
                        value={options?.quarantine?.end}
                        error={errors?.options?.quarantine?.end}
                        onChange={handleChange}
                        disabled={!options.isExQuarantine}
                        touched={touched.options?.quarantine}
                        onBlur={handleBlur}
                        isErrorVisible={isErrorsVisible}
                        required={false}
                        innerRef={isFieldInFocus?.('options.quarantine.end')}
                        onFocus={(e) => handleFocusField(isMobile, e.target)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </>
          )}
          {!isHiddenField(defaultLocale, 'options.isMoving') && (
            <>
              <Grid item xs={12} md={6}>
                <Radio
                  name="options.isMoving"
                  label="Are you moving house?"
                  tooltip="We may share your details with trusted partners who specialize in moving your route."
                  iconTooltip={<QuestionMarkSVG />}
                  value={options.isMoving}
                  error={String(errors?.options?.isMoving)}
                  onChange={handleChange}
                  onFocus={(e) => handleFocusField(isMobile, e.target)}
                  menuItems={[
                    {
                      label: 'No',
                      value: false,
                    },
                    {
                      label: 'Yes',
                      value: true,
                    },
                  ]}
                  innerRef={isFieldInFocus?.('options.isMoving')}
                />
              </Grid>
              {options.isMoving && (
                <Grid item xs={12} md={6}>
                  <Radio
                    name="options.isDataSharingEnabled"
                    label="Would you like our preferred removal partner to contact you?"
                    value={options.isDataSharingEnabled}
                    error={String(errors?.options?.isDataSharingEnabled)}
                    onChange={handleChange}
                    onFocus={(e) => handleFocusField(isMobile, e.target)}
                    menuItems={[
                      {
                        label: 'No',
                        value: false,
                      },
                      {
                        label: 'Yes',
                        value: true,
                      },
                    ]}
                    innerRef={isFieldInFocus?.('options.isDataSharingEnabled')}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </>
          )}
          {!isHiddenField(defaultLocale, 'options.isBreeder') && (
            <>
              <Grid item xs={12} md={6}>
                <Radio
                  name="options.isBreeder"
                  label="Are you a breeder?"
                  value={options.isBreeder}
                  tooltip={renderTooltipForAU(
                    'Dogtainers have a specialised Breeder Support team to assist you with your transportation needs.',
                  )}
                  error={String(errors?.options?.isBreeder)}
                  onChange={handleChange}
                  onFocus={(e) => handleFocusField(isMobile, e.target)}
                  menuItems={[
                    {
                      label: 'No',
                      value: false,
                    },
                    {
                      label: 'Yes',
                      value: true,
                    },
                  ]}
                  innerRef={isFieldInFocus?.('options.isBreeder')}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </>
          )}
          {!isHiddenField(
            defaultLocale,
            'options.isAustralianDefenceForceMember',
          ) &&
            values.quoteType === 'domestic' && (
              <>
                <Grid item xs={12} md={6}>
                  <Radio
                    name="options.isAustralianDefenceForceMember"
                    label="Is this an Australian Defence Force move?"
                    value={options.isAustralianDefenceForceMember}
                    onChange={handleChange}
                    onFocus={(e) => handleFocusField(isMobile, e.target)}
                    menuItems={[
                      {
                        label: 'No',
                        value: false,
                      },
                      {
                        label: 'Yes',
                        value: true,
                      },
                    ]}
                    innerRef={isFieldInFocus?.(
                      'options.isAustralianDefenceForceMember',
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </>
            )}
          <Grid item xs={12} md={6}>
            <Input
              label="Additional comments"
              placeholder="Placeholder text"
              name="options.comments"
              tooltip={renderTooltipForAU(
                'Please add anything else you think we should know about your pet or special requirements for your pets transport.',
              )}
              required={false}
              multiline={true}
              rows={3}
              onChange={handleChange}
              value={options?.comments}
              error={errors?.options?.comments}
              isErrorVisible={isErrorsVisible}
              innerRef={isFieldInFocus?.('options.comments')}
              onFocus={(e) => handleFocusField(isMobile, e.target)}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
