/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FormikTouched, useFormik } from 'formik';
import { navigate } from 'gatsby';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';

import { PageControl } from '@dogtainers/dgt-blocks/src/components/form';
import {
  BlockBuilder,
  BlockForm,
} from '@dogtainers/dgt-blocks/src/types/_blocks.types';
import { Box, CircularProgress, Grid, Snackbar } from '@material-ui/core';
import { WindowLocation, useLocation } from '@reach/router';

import { ErrorBlock } from '../../components/ErrorBlock';
import { API_URL } from '../../environment';
import { useScrollToError } from '../../hooks/useScrollToError';
import { LoadingContext } from '../../layouts/site.layout';
import { NestedKeyOf, getValue } from '../../utils/data';
import { localiseUrl } from '../../utils/language';
import { parseErrors } from '../utils/validation';
import {
  BookingForm,
  BookingFormPartial,
  PageFormProps,
} from './booking.types';
import { mapFieldToPage, validationSchema } from './booking.validation';
import { BookingPage1 } from './pages/page1';
import { BookingPage2 } from './pages/page2';
import { BookingPage3 } from './pages/page3';
import { BookingPage4 } from './pages/page4';
import { BookingPage5 } from './pages/page5';
import { BookingPage6 } from './pages/page6';

type Props = BlockBuilder<BlockForm>;

export type Consultant = {
  id: string;
  name: string;
  email: string;
  phoneNumber: string;
  jobTitle: string;
  createdOn: string;
  savedOn: string;
  createdBy: string;
};

const initRootFormValues = (): BookingFormPartial => ({
  pets: [
    {
      id: uuidv4(),
    },
  ],
});

const prepareSubmitData = (formData: BookingFormPartial) => {
  let filteredFormData = {
    ...formData,
  };

  if (formData.options) {
    const filterOptions = Object.entries(formData.options).reduce(
      (acc, [key, value]) => {
        if (key !== 'confirmAllInformation')
          acc[key] = value as string | boolean;
        return acc;
      },
      {} as Record<string, string | boolean>,
    );

    filteredFormData = {
      ...formData,
      options: {
        ...filterOptions,
      },
    };
  }

  return {
    enhancedBooking: {
      consultantId: filteredFormData.consultantId,
      departure: {
        ...filteredFormData.departure,
      },
      options: {
        ...filteredFormData.options,
      },
      arrival: {
        ...filteredFormData.arrival,
      },
      contact: {
        ...filteredFormData.contact,
      },
      petTravellingBy: filteredFormData.petTravellingBy,
      hearMoreAboutProducts: {
        ...filteredFormData.hearMoreAboutProducts,
      },
      quotedAirline: filteredFormData.quotedAirline,
      sender: {
        ...filteredFormData.sender,
      },
      receiver: { ...filteredFormData.receiver },
      pets: filteredFormData.pets,
    },
  };
};

const getParams = (
  location: WindowLocation<unknown>,
): Record<string, string | null> => {
  const params = new URLSearchParams(location.search);
  const consultantId = params.get('consultantId');
  const quoteId = params.get('id');

  return { consultantId, quoteId };
};

export const FormBooking: React.FC<Props> = () => {
  const [toastMsg, setToastMsg] = useState<string | null>(null);
  const [isMounted, setMounted] = useState(false);

  const [isDraft, setIsDraft] = useState(false);
  const [consultantId, setConsultantId] = useState<string | null>(null);

  const { setOverlay } = useContext(LoadingContext);
  const [activeStep, setActiveStep] = useState(1);

  const formElementId = 'form-submission-errors';
  const {
    isFieldInFocus,
    fieldInFocus,
    setFieldInFocus,
    isErrorsVisible,
    setErrorsVisible,
  } = useScrollToError(formElementId);

  const location = useLocation();

  const formik = useFormik<BookingFormPartial>({
    initialValues: initRootFormValues(),
    validationSchema: consultantId || isDraft ? null : validationSchema,
    onSubmit: async (values: BookingFormPartial) => {
      setOverlay(isDraft ? 'Submitting draft..' : 'Submitting booking..');
      try {
        await submitForm(values);
        setToastMsg(isDraft ? 'Booking saved!' : 'Booking submitted!');
        if (isDraft) setIsDraft(false);
        else navigate(localiseUrl('thankyou-smart-booking'));
      } catch (e: unknown) {
        setToastMsg((e as Error).message);
      } finally {
        setOverlay(undefined);
      }
    },
  });

  const {
    handleChange,
    handleSubmit,
    touched,
    handleBlur,
    errors,
    dirty,
    isValid,
    values,
    setValues,
    setFieldValue,
    validateForm,
  } = formik;

  useEffect(() => {
    validateForm();
  }, []);

  const fetchQuote = useCallback(async (quoteId: string) => {
    try {
      const quoteData = await fetch(`${API_URL}/bookings/${quoteId}`, {
        method: 'GET',
      }).then((res) => res.json());

      const prefilledQuoteData = {
        ...quoteData,
        pets: (quoteData as BookingFormPartial)?.pets?.map((pet) => ({
          ...pet,
          breed: pet.breed || pet.breedId || pet.breedOther,
        })),
      };

      setValues(prefilledQuoteData);
    } catch (e) {
      console.error(e);
    } finally {
      setMounted(true);
    }
  }, []);

  const submitForm = useCallback(
    async (formData: BookingFormPartial): Promise<void> => {
      const submitData = prepareSubmitData(formData);

      const queryParams = {
        consultantId: consultantId ? `/?consultantId=${consultantId}` : '',
        isDraft: isDraft ? `${consultantId ? '&' : '/?'}isDraft=1` : '',
      };

      const result = await fetch(
        `${API_URL}/bookings/${formData.id}/finalized${queryParams.consultantId}${queryParams.isDraft}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          redirect: 'follow',
          body: JSON.stringify(submitData),
        },
      );

      if (result.status !== 200) {
        let responseBody = '';
        try {
          responseBody = await result.json();
        } catch {
          responseBody = await result.text();
        }
        console.error('Error submitting form', responseBody);
        throw Error(
          'Unable to submit form - please try again, or give us a call',
        );
      }
    },
    [isDraft, consultantId],
  );

  const getActivePage = useCallback((step: number): React.FC<PageFormProps> => {
    switch (step) {
      case 1:
        return BookingPage1;
      case 2:
        return BookingPage2;
      case 3:
        return BookingPage3;
      case 4:
        return BookingPage4;
      case 5:
        return BookingPage5;
      case 6:
        return BookingPage6;
      default:
        throw Error(`Unknown form page #${step}`);
    }
  }, []);

  const ActiveForm = useMemo(() => getActivePage(activeStep), [activeStep]);

  const updateForm = useCallback((newData: BookingFormPartial) => {
    setValues({
      ...newData,
    });
  }, []);

  const isValueEqual = useCallback(
    (fieldName: string, testValue: string | boolean) =>
      getValue(values, fieldName as NestedKeyOf<BookingFormPartial>) ===
      testValue,
    [values],
  );

  const saveDraft = useCallback(() => {
    setIsDraft(true);
    // to be sure isDraft is true
    setTimeout(handleSubmit, 0);
  }, []);

  const nextStep = useCallback(() => {
    setActiveStep((c) => c + 1);
  }, []);

  const prevStep = useCallback(() => {
    setActiveStep((c) => c - 1);
  }, []);

  const goToStep = useCallback((step: number) => {
    setActiveStep(step);
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      !fieldInFocus && window.scrollTo(0, 0);
    }
  }, [activeStep]);

  useEffect(() => {
    const { consultantId, quoteId } = getParams(location);

    setConsultantId(consultantId);

    if (quoteId) fetchQuote(quoteId);
    else setMounted(true);
  }, [fetchQuote]);

  const errorMsgs = parseErrors(errors);

  function onClickError(field: string) {
    goToStep(mapFieldToPage(field));
    setFieldInFocus(field);
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!toastMsg}
        autoHideDuration={3000}
        onClose={() => setToastMsg(null)}
        message={toastMsg}
      />
      {isMounted ? (
        <Box>
          <form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid container justifyContent="center">
              <Grid item xs={12} md={10} style={{ marginTop: '20px' }}>
                <ActiveForm
                  values={values}
                  errors={errors as BookingFormPartial}
                  updateForm={updateForm}
                  handleChange={handleChange}
                  nextPage={nextStep}
                  prevPage={prevStep}
                  isValueEqual={isValueEqual}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  touched={touched as FormikTouched<BookingForm>}
                  isErrorsVisible={isErrorsVisible}
                  isFieldInFocus={isFieldInFocus}
                >
                  {isErrorsVisible && !!errorMsgs.length && (
                    <ErrorBlock
                      onClickError={onClickError}
                      formElementId={formElementId}
                      errorMsgs={errorMsgs}
                    />
                  )}
                </ActiveForm>
              </Grid>
              {activeStep && (
                <Grid item xs={12} md={10} style={{ paddingTop: '4rem' }}>
                  <PageControl
                    currentPage={activeStep}
                    totalPages={6}
                    nextPage={nextStep}
                    prevPage={prevStep}
                    saveDraft={saveDraft}
                    onCancel={() => navigate(localiseUrl('/'))}
                    canSubmit={dirty && isValid}
                    setToastMsg={setToastMsg}
                    setErrorsVisible={setErrorsVisible}
                  />
                </Grid>
              )}
            </Grid>
          </form>
        </Box>
      ) : (
        <Box
          style={{
            position: 'absolute',
            width: '100%',
            height: '100vh',
            top: 0,
            left: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress size="4rem" />
        </Box>
      )}
    </>
  );
};
