import { graphql, useStaticQuery } from 'gatsby';
import React, { useMemo } from 'react';

import { Autocomplete, Input } from '@dogtainers/dgt-blocks/src/components/form';
import { AutocompleteItem } from '@dogtainers/dgt-blocks/src/components/form/autocomplete';
import { useIsMobile } from '@dogtainers/dgt-blocks/src/hooks/useBreakpoints';
import { Grid, Typography } from '@material-ui/core';
import IconLock from '@material-ui/icons/LockOutlined';

import { defaultLocale } from '../../../utils/language';
import { handleFocusField } from '../../utils';
import { FormDataQuery, PageFormProps, useFormStyles } from '../quote.form';
import { QuoteForm } from '../quote.types';
import { isHiddenField } from '../quote.validation';

export const QuotePage3: React.FC<PageFormProps> = ({
  values,
  handleChange,
  errors,
  touched,
  handleBlur,
  isErrorsVisible,
  isFieldInFocus,
  children,
}) => {
  const query = graphql`
    query QuotePage3 {
      allCountriesJson {
        nodes {
          id
          label
        }
      }
    }
  `;
  const classes = useFormStyles();
  const isMobile = useIsMobile();
  const staticData = useStaticQuery<FormDataQuery>(query);
  const countries: AutocompleteItem[] = staticData.allCountriesJson.nodes.map(
    (n) => ({ value: n.id, label: n.label }),
  );

  const contact: Partial<QuoteForm['contact']> = {
    ...values.contact,
  };

  const isHongKong = useMemo(() => contact?.countryId === 'HK', [contact]);

  return (
    <Grid container justifyContent="center" spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h4">Contact details</Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1} className={classes.container}>
          <Grid item>
            <IconLock fontSize="small" className={classes.icon} />
          </Grid>
          <Grid item>
            <Typography variant="body1">
              We only require your details for communicating with you. They are
              secure and will not be shared outside of Dogtainers
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {children}
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Input
              label="First name"
              name="contact.nameFirst"
              onChange={handleChange}
              value={contact?.nameFirst}
              error={errors?.contact?.nameFirst}
              touched={touched.contact?.nameFirst}
              isErrorVisible={isErrorsVisible}
              onBlur={handleBlur}
              innerRef={isFieldInFocus?.('contact.nameFirst')}
              onFocus={(e) => handleFocusField(isMobile, e.target)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              label="Last name"
              name="contact.nameLast"
              onChange={handleChange}
              value={contact?.nameLast}
              error={errors?.contact?.nameLast}
              touched={touched.contact?.nameLast}
              isErrorVisible={isErrorsVisible}
              onBlur={handleBlur}
              innerRef={isFieldInFocus?.('contact.nameLast')}
              onFocus={(e) => handleFocusField(isMobile, e.target)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              label="Email"
              name="contact.email"
              onChange={handleChange}
              value={contact?.email}
              error={errors?.contact?.email}
              touched={touched.contact?.email}
              isErrorVisible={isErrorsVisible}
              onBlur={handleBlur}
              innerRef={isFieldInFocus?.('contact.email')}
              onFocus={(e) => handleFocusField(isMobile, e.target)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              label="Phone"
              name="contact.phone"
              onChange={handleChange}
              value={contact?.phone}
              error={errors?.contact?.phone}
              touched={touched.contact?.phone}
              isErrorVisible={isErrorsVisible}
              onBlur={handleBlur}
              innerRef={isFieldInFocus?.('contact.phone')}
              onFocus={(e) => handleFocusField(isMobile, e.target)}
            />
          </Grid>
          {!isHongKong && !isHiddenField(defaultLocale, 'contact.postcode') && (
            <Grid item xs={12} sm={6}>
              <Input
                label="Post code"
                name="contact.postcode"
                onChange={handleChange}
                value={contact?.postcode}
                error={errors?.contact?.postcode}
                touched={touched.contact?.postcode}
                isErrorVisible={isErrorsVisible}
                onBlur={handleBlur}
                innerRef={isFieldInFocus?.('contact.postcode')}
                onFocus={(e) => handleFocusField(isMobile, e.target)}
                required={defaultLocale === 'en-AU'}
              />
            </Grid>
          )}
          {values.quoteType === 'international' && (
            <Grid item xs={12} sm={6}>
              <Autocomplete
                name="contact.countryId"
                label="Country"
                required={false}
                menuItems={countries}
                error={errors?.contact?.countryId}
                value={contact?.countryId}
                onChange={handleChange}
                touched={touched.contact?.countryId}
                isErrorVisible={isErrorsVisible}
                onBlur={handleBlur}
                innerRef={isFieldInFocus?.('contact.countryId')}
                onFocus={(e) => handleFocusField(isMobile, e.target)}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
