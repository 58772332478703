import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { DropzoneOptions, FileRejection, useDropzone } from 'react-dropzone';

import { useErrorStyles } from '@dogtainers/dgt-blocks/src/components/form/form.styles';
import { FormHelperText, FormLabel, Grid, Typography } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import { FileValue } from '../../forms/booking/booking.types';
import FileCard from './components/FileCard/fileCard';
import useStyles from './dropzone.styles';
import {
  AwsData,
  DropzoneFile,
  PreparedFile,
  SubmitStatus,
} from './dropzone.types';
import {
  onDropValidation,
  prepareFieldValue,
  prepareInitDropzoneValue,
  preparePayloadsVariables,
} from './dropzone.utils';

export type DropzoneProps = {
  name: string;
  error?: string;
  value?: FileValue[];
  label?: string;
  quoteId?: string;
  text?: string;
  acceptTypeText?: string;
  required?: boolean;
  isErrorVisible?: boolean;
  setFieldValue?: (fieldName: string, fieldValue: FileValue[]) => void;
  innerRef?: React.MutableRefObject<HTMLInputElement> | undefined;
  fetchAwsFiles: (
    files: DropzoneFile[],
    quoteId?: string,
    recaptchaToken?: string | null,
  ) => Promise<AwsData>;
} & DropzoneOptions;

const Dropzone: React.FC<DropzoneProps> = (props) => {
  const {
    setFieldValue,
    value = [],
    name,
    error,
    maxFiles = 0,
    maxSize = Infinity,
    label,
    required = false,
    text,
    acceptTypeText,
    isErrorVisible,
    innerRef,
    fetchAwsFiles,
    ...rest
  } = props;

  const classes = useStyles();
  const { errorFont, errorBlock } = useErrorStyles();

  const [files, setFiles] = useState<DropzoneFile[]>(
    prepareInitDropzoneValue(value),
  );

  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const setFilesStatus = useCallback(
    (fileName: string, status: SubmitStatus) => {
      setFiles((files) =>
        files.map((file) =>
          file.name === fileName
            ? {
                ...file,
                status,
              }
            : file,
        ),
      );
    },
    [],
  );

  const handleOnDrop = useCallback(
    async (droppedFiles: Array<File>, fileRejections: Array<FileRejection>) => {
      const [acceptedFiles, errorMessage] = onDropValidation(
        files,
        droppedFiles,
        fileRejections,
        maxFiles,
        maxSize,
      );

      setErrorMessage(errorMessage);

      const newFiles = acceptedFiles.map(
        (file) =>
          ({
            name: file.name,
            type: file.type,
            blobURL: URL.createObjectURL(file),
            status: 'pending',
          } as DropzoneFile),
      );

      setFiles((prevFiles) => [...prevFiles, ...newFiles]);

      if (!errorMessage)
        try {
          const filesParams = await fetchAwsFiles(newFiles);

          const preparedFiles = (
            await Promise.all(
              newFiles.map(async (file, index) => {
                const prepFile = (await preparePayloadsVariables(
                  filesParams.files[index],
                  file,
                )) as PreparedFile;

                setFiles((files) =>
                  files.map((file) => {
                    if (file.name === prepFile.name)
                      return {
                        ...file,
                        key: prepFile.key,
                      };
                    return file;
                  }),
                );

                return prepFile;
              }),
            )
          ).filter(Boolean) as PreparedFile[];

          // upload images
          await Promise.allSettled(
            preparedFiles.map(({ name, url, formData }) =>
              fetch(url, {
                method: 'POST',
                body: formData,
              })
                .then(() => setFilesStatus(name, 'succeeded'))
                .catch((e) => {
                  setFilesStatus(name, 'failed');
                  console.error(e);
                }),
            ),
          );
        } catch (e) {
          console.error(e);
          newFiles.forEach((file) => setFilesStatus(file.name, 'failed'));
        }

      // Revoke the data uris to avoid memory leaks
      newFiles.forEach(
        (file) => file.blobURL && URL.revokeObjectURL(file.blobURL),
      );
    },
    [files, setFiles, maxFiles, maxSize],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleOnDrop,
    maxFiles,
    maxSize,
    ...rest,
  });

  const handleRemoveFile = useCallback(
    (removeFileName: string) => {
      const newFiles = files.filter(({ name }) => name !== removeFileName);
      setFiles(newFiles);
      setErrorMessage(undefined);
    },
    [files],
  );

  useEffect(() => {
    const newValue = prepareFieldValue(files);
    setFieldValue?.(name, newValue);
  }, [files]);

  return (
    <Grid>
      <FormLabel component="legend" className={classes.label}>
        {label}
        {required && <span>*</span>}
      </FormLabel>
      <Grid
        {...getRootProps()}
        className={classes.dropzoneContainer}
        ref={innerRef}
      >
        <input {...getInputProps()} />
        <AddCircleOutlineIcon className={classes.addCircleOutlineIcon} />
        {text && <Typography>{text}</Typography>}
        {acceptTypeText && <Typography>{acceptTypeText}</Typography>}
      </Grid>
      {(errorMessage || error) && isErrorVisible && (
        <FormHelperText className={classNames(errorFont, errorBlock)}>
          {errorMessage || error}
        </FormHelperText>
      )}

      {files.map((file) => (
        <FileCard
          status={file.status ?? 'succeeded'}
          onClikRemove={handleRemoveFile}
          key={file.name || file.key}
        >
          {file.name}
        </FileCard>
      ))}
    </Grid>
  );
};

export default Dropzone;
