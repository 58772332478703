import { navigate } from 'gatsby';
import React, { FormEventHandler, useState } from 'react';

import { Input } from '@dogtainers/dgt-blocks/src/components/form';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  Snackbar,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import type { StripeCardElementOptions } from '@stripe/stripe-js';

import { API_URL, DEFAULT_LOCALE } from '../environment';
import { QuoteForm } from '../forms/quote/quote.types';
import { useAnalytics } from '../hooks/useAnalytics';
import { useEnsureQuoteForm } from '../hooks/useEnsureQuoteForm';
import CardSVG from '../images/payment/cardSVG';
import VisaSVG from '../images/payment/visaSVG';
import { calculateDayDateDiff } from '../utils/date';
import { defaultLocale, localiseUrl } from '../utils/language';
import { WhiteButton } from './buttons';
import { useStore } from './store';

type CustomCardElementOptions = StripeCardElementOptions & {
  'data-hj-allow'?: boolean;
};

const CardElementOptions: CustomCardElementOptions = {
  iconStyle: 'solid',
  style: {
    base: {
      fontSize: '16px',
    },
  },
  'data-hj-allow': false,
};

const useStyles = makeStyles(() => ({
  stripeForm: {
    '& .StripeElement': {
      border: '1px solid #999',
      padding: 10,
      background: 'white',
    },
  },
}));

const CardForm: React.FC = () => {
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();
  const sendPurchaseEvent = useAnalytics();
  const sendPaymentConfirm = useAnalytics();

  const bookingQuote = useStore((state) => state.bookingQuote);

  const [accept, setAccpet] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  const { nameFirst, nameLast, email, phone } = bookingQuote?.contact ?? {};
  const [billingDetails, setBillingDetails] = useState({
    firstName: nameFirst || '',
    lastName: nameLast || '',
    email: email || '',
    phone: phone || '',
  });

  const [toastMsg, setToastMsg] = useState<string | null>(null);
  const [stripeClientSecret, setStripeClientSecret] = useState<string>();
  const [paymentSurcharge, setPaymentSurcharge] = useState<number>(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isConfirmingPayment, setIsConfirmingPayment] = useState(false);

  useEnsureQuoteForm();

  const onCancel = () => {
    const url = 'quote-confirmation';
    navigate(localiseUrl(url));
  };

  const onClickAccept = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAccpet(e.target.checked);
  };

  const handleSubmit: FormEventHandler = async (e) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    e.preventDefault();
    setIsProcessing(true);

    const card = elements?.getElement(CardElement);

    if (!stripe || !elements || !card) {
      setIsProcessing(false);
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { paymentMethod, error: paymentMethodError } =
      await stripe.createPaymentMethod({
        type: 'card',
        card,
        billing_details: {
          name: `${billingDetails.firstName} ${billingDetails.lastName}`,
          email: billingDetails.email,
          phone: billingDetails.phone,
        },
      });

    if (paymentMethodError) {
      setToastMsg(
        paymentMethodError.message ||
          'Failed creating payment method, please try again',
      );
      setIsProcessing(false);
      return;
    }

    if (paymentMethod) {
      try {
        const result = await fetch(`${API_URL}/bookings/initiate-payment`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            bookingId: bookingQuote?.id,
            // You can't call this route twice with same payment method, so you should recreate it every time
            // user creates some changes
            paymentMethodId: paymentMethod.id,
          }),
        });

        if (result.status !== 200) {
          const responseBody = await result.json();
          setToastMsg(responseBody.error);
          setIsProcessing(false);
        } else {
          const responseBody = await result.json();
          const { clientSecret, surcharge } = responseBody;
          setStripeClientSecret(clientSecret);
          setPaymentSurcharge(surcharge);

          if (surcharge === 0) {
            // If surcharge doesn't exist, auto confirm
            confirmCardPayment(clientSecret);
          }
        }
      } catch (e) {
        console.log(e);
        setIsProcessing(false);
      }
    }
  };

  const onAgreeSurCharge = () => {
    if (stripeClientSecret) {
      confirmCardPayment(stripeClientSecret);
    }
  };

  const confirmCardPayment = async (clientSecret: string) => {
    if (!stripe || !elements) {
      setIsProcessing(false);
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setIsConfirmingPayment(true);

    const { error: stripeError } = await stripe.confirmCardPayment(
      clientSecret,
    );

    if (stripeError) {
      setIsProcessing(false);
      setToastMsg(stripeError.message || 'Payment failed');
      setIsConfirmingPayment(false);
      return;
    }

    const { arrival, departure, pets, options, consultantId, contact } =
      bookingQuote as QuoteForm;

    sendPurchaseEvent({
      event: 'quotePurchased',
      value: {
        orderValue: bookingQuote?.totalCost?.amount,
        currency: 'AUD',
        pets: pets.map((pet) => ({ type: pet.typeId, breed: pet.breedId })),
        petTypes: pets.map((pet) => pet.typeId),
        petBreeds: pets.map((pet) => pet.breedId),
        from: departure.airportId,
        to: arrival.airportId,
        pickup: departure.isPickup,
        deliver: arrival.isDelivery,
        postcode: contact.postcode,
        office: DEFAULT_LOCALE.split('-')[1],
        boarding: options.requiresPetAccommodation,
        returnFlight: options.requiresReturnFlight,
        moving: options.isMoving,
        breeder: options.isBreeder,
        exQuarantine: options.isExQuarantine,
        boardingDays: calculateDayDateDiff(
          options.petAccommodation?.end as string,
          options.petAccommodation?.start as string,
        ),
        consultantId: consultantId,
        travelDate: departure.date,
      },
    });

    sendPaymentConfirm({
      event: 'paymentConfirm',
    });

    setIsConfirmingPayment(false);
    const url = 'thankyou-booking';
    navigate(localiseUrl(url));
    setIsProcessing(false);
  };

  const handleChangeBillingDetails = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setBillingDetails({
      ...billingDetails,
      [e.target.name]: e.target.value,
    });
  };

  const onDisagree = () => {
    setPaymentSurcharge(0);
    setIsProcessing(false);
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!toastMsg}
        autoHideDuration={3000}
        onClose={() => setToastMsg(null)}
        message={toastMsg}
      />
      <Box py={5}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Input
              label="First name"
              name="firstName"
              onChange={handleChangeBillingDetails}
              value={billingDetails.firstName}
              dataHjAllow={defaultLocale !== 'en-GB'}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              label="Last name"
              name="lastName"
              onChange={handleChangeBillingDetails}
              value={billingDetails.lastName}
              dataHjAllow={defaultLocale !== 'en-GB'}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              label="Email"
              name="email"
              onChange={handleChangeBillingDetails}
              value={billingDetails.email}
              dataHjAllow={defaultLocale !== 'en-GB'}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              label="Phone"
              name="phone"
              onChange={handleChangeBillingDetails}
              value={billingDetails.phone}
              dataHjAllow={defaultLocale !== 'en-GB'}
            />
          </Grid>
        </Grid>
      </Box>

      <Divider />
      <Box pt={5}>
        <Box
          mb={4}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant={matches ? 'h6' : 'h5'}>
            Payment by credit card
          </Typography>
          <Box display="flex">
            <Box mr={1}>
              <CardSVG />
            </Box>

            <VisaSVG />
          </Box>
        </Box>

        <Box mb={5}></Box>
      </Box>

      <form id="payment-form" onSubmit={handleSubmit}>
        <Box mb={5} className={classes.stripeForm}>
          <CardElement id="card" options={CardElementOptions} />
        </Box>

        <Box mb={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={accept}
                onChange={onClickAccept}
                name="checkedA"
              />
            }
            label="I have read and accepted the TERMS OF USE"
          />

          <Box textAlign="right">
            <Typography>
              Payment Total <br />${bookingQuote?.totalCost?.amount}
            </Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          flexDirection={!matches ? 'row' : 'column-reverse'}
          mb={2}
        >
          <Box mr={!matches ? 4 : 0} mt={matches ? 2 : 0}>
            <WhiteButton variant="contained" fullWidth onClick={onCancel}>
              Cancel
            </WhiteButton>
          </Box>

          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!accept || isProcessing}
          >
            {isProcessing ? (
              <CircularProgress size={20} color="secondary" />
            ) : (
              'Confirm'
            )}
          </Button>
        </Box>
      </form>
      <Dialog
        open={paymentSurcharge !== 0}
        onClose={onDisagree}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Do you agree with a surcharge of ${paymentSurcharge}?
        </DialogTitle>
        <DialogActions>
          <Button onClick={onDisagree} color="primary">
            Disagree
          </Button>
          <Button
            onClick={onAgreeSurCharge}
            color="primary"
            autoFocus
            disabled={isConfirmingPayment}
          >
            {isConfirmingPayment ? (
              <CircularProgress size={20} color="secondary" />
            ) : (
              'Agree'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CardForm;
