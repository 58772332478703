import { blocks } from '@dogtainers/dgt-blocks';

import { FormBlock } from './form.block';

const {
  BrandsBlock,
  ContentBlock,
  CtaBlock,
  FaqBlock,
  FeaturesBlock,
  HeadingBlock,
  TableBlock,
  HeroBlock,
  IconGridBlock,
  ImageBlock,
  LocationsBlock,
  ReviewsBlock,
  VideoBlock,
} = blocks;

export {
  BrandsBlock,
  ContentBlock,
  CtaBlock,
  FaqBlock,
  FeaturesBlock,
  FormBlock,
  HeadingBlock,
  TableBlock,
  HeroBlock,
  IconGridBlock,
  ImageBlock,
  LocationsBlock,
  ReviewsBlock,
  VideoBlock,
};
