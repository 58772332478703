import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import {
  Autocomplete,
  DatePicker,
  Input,
  Radio,
} from '@dogtainers/dgt-blocks/src/components/form';
import {
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import Dropzone from '../../../components/Dropzone/dropzone';
import { fetchAwsFilesParamsBooking } from '../../../components/Dropzone/dropzone.utils';
import { FileValue, PageFormProps } from '../booking.types';
import useStyles from './styles';

const radioToggleItems = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

export const BookingPage5: React.FC<PageFormProps> = ({
  values,
  handleChange,
  errors: { pets: petsErrors },
  setFieldValue,
  isValueEqual,
  updateForm,
  handleBlur,
  touched,
  isErrorsVisible,
  isFieldInFocus,
  children,
}) => {
  const { pageTitle, pageSubTitle, dropzoneWrapper, whiteButton } = useStyles();
  const webQuoteId = new URLSearchParams(location.search).get('id') || '';

  type PetPartial = Exclude<typeof values.pets, undefined>[number];
  const addPet = (): PetPartial => {
    const oldPets: PetPartial[] = values.pets ?? [];
    const newPet: PetPartial = { id: uuidv4() };
    updateForm({
      ...values,
      pets: [...oldPets, newPet],
    });
    return newPet;
  };

  const pets: PetPartial[] = values.pets?.length
    ? [...values.pets]
    : [addPet()];

  const deletePet = (pet: PetPartial) => {
    const updatedPets = pets.filter((p) => p.id !== pet.id);
    updateForm({
      ...values,
      pets: updatedPets,
    });
  };

  return (
    <Grid container justifyContent="center" spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h4" className={pageTitle}>
          Booking Form
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" className={pageSubTitle}>
          Pet Details
        </Typography>
      </Grid>
      {children}
      {pets.map((pet, idx) => (
        <React.Fragment key={`${pet.id}${idx}`}>
          {pets.length > 1 && (
            <Grid item xs={12} style={{ marginTop: '1rem' }}>
              <List dense>
                <ListItem style={{ padding: 0 }}>
                  <ListItemText>
                    <Typography variant="h5">Pet {idx + 1}</Typography>
                  </ListItemText>
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => deletePet(pet)}
                      style={{ paddingRight: 0 }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </Grid>
          )}
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid item xs={12} md={6} className={dropzoneWrapper}>
                  <Dropzone
                    label="Photo of your pet"
                    text="Upload Photos"
                    acceptTypeText="png, jpeg, heic"
                    name={`pets[${idx}].photos`}
                    setFieldValue={setFieldValue}
                    maxFiles={2}
                    maxSize={10000000}
                    accept={{
                      'image/png': [],
                      'image/jpeg': [],
                      'image/heic': [],
                    }}
                    value={pet?.photos as FileValue[]}
                    error={String(petsErrors?.[idx]?.photos ?? '')}
                    required
                    isErrorVisible={isErrorsVisible}
                    fetchAwsFiles={(newFiles) =>
                      fetchAwsFilesParamsBooking(newFiles, webQuoteId)
                    }
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Input
                  label="Pet Name"
                  name={`pets[${idx}].name`}
                  placeholder="e.g. Spot"
                  onChange={handleChange}
                  value={pet?.name}
                  error={petsErrors?.[idx]?.name}
                  onBlur={handleBlur}
                  touched={touched.pets?.[idx]?.name}
                  isErrorVisible={isErrorsVisible}
                  innerRef={isFieldInFocus?.(`pets.${idx}.name`)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Input
                  label="Breed"
                  name={`pets[${idx}].breed`}
                  placeholder="e.g Labrador"
                  onChange={handleChange}
                  value={pet?.breed}
                  error={petsErrors?.[idx]?.breed}
                  onBlur={handleBlur}
                  touched={touched.pets?.[idx]?.breed}
                  isErrorVisible={isErrorsVisible}
                  innerRef={isFieldInFocus?.(`pets.${idx}.breed`)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Input
                  label="Weight"
                  type="number"
                  name={`pets[${idx}].weight`}
                  suffix="KG"
                  onChange={handleChange}
                  value={pet?.weight}
                  error={String(petsErrors?.[idx]?.weight ?? '')}
                  onBlur={handleBlur}
                  touched={touched.pets?.[idx]?.weight}
                  isErrorVisible={isErrorsVisible}
                  innerRef={isFieldInFocus?.(`pets.${idx}.weight`)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  label="Sex"
                  name={`pets[${idx}].sex`}
                  onChange={handleChange}
                  menuItems={[
                    {
                      label: 'Male',
                      value: 'male',
                    },
                    {
                      label: 'Female',
                      value: 'female',
                    },
                  ]}
                  value={pet?.sex}
                  error={petsErrors?.[idx]?.sex}
                  onBlur={handleBlur}
                  touched={touched.pets?.[idx]?.sex}
                  isErrorVisible={isErrorsVisible}
                  innerRef={isFieldInFocus?.(`pets.${idx}.sex`)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Input
                  label="Colour"
                  name={`pets[${idx}].colour`}
                  placeholder="e.g White with brown patches"
                  onChange={handleChange}
                  value={pet?.colour}
                  error={String(petsErrors?.[idx]?.colour ?? '')}
                  onBlur={handleBlur}
                  touched={touched.pets?.[idx]?.colour}
                  isErrorVisible={isErrorsVisible}
                  innerRef={isFieldInFocus?.(`pets.${idx}.colour`)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DatePicker
                  label="Pet Birthdate"
                  name={`pets[${idx}].birthdate`}
                  onChange={handleChange}
                  value={pet?.birthdate}
                  error={String(petsErrors?.[idx]?.birthdate ?? '')}
                  onBlur={handleBlur}
                  touched={touched.pets?.[idx]?.birthdate}
                  isErrorVisible={isErrorsVisible}
                  innerRef={isFieldInFocus?.(`pets.${idx}.birthdate`)}
                />
              </Grid>
              <Grid item xs={12}>
                <Radio
                  label="Crate Requirements"
                  name={`pets[${idx}].crate.type`}
                  menuItems={[
                    {
                      label: 'Hire Crate',
                      value: 'hire',
                    },
                    {
                      label: 'Purchase Crate',
                      value: 'buy',
                    },
                    {
                      label: 'Your own airline approved transport crate',
                      value: 'own',
                    },
                  ]}
                  onChange={handleChange}
                  value={pet?.crate?.type}
                  error={String(petsErrors?.[idx]?.crate?.type)}
                  isErrorVisible={isErrorsVisible}
                  innerRef={isFieldInFocus?.(`pets.${idx}.crate.type`)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  label="Please confirm crate size"
                  name={`pets[${idx}].confirmCrateSize`}
                  menuItems={[
                    {
                      label: 'PP20/K11',
                      value: 'PP20/K11',
                    },
                    {
                      label: 'PP30',
                      value: 'PP30',
                    },
                    {
                      label: 'PP40/K29',
                      value: 'PP40/K29',
                    },
                    {
                      label: 'PP50',
                      value: 'PP50',
                    },
                    {
                      label: 'PP60',
                      value: 'PP60',
                    },
                    {
                      label: 'PP70',
                      value: 'PP70',
                    },
                    {
                      label: 'PP80',
                      value: 'PP80',
                    },
                    {
                      label: 'PP90',
                      value: 'PP90',
                    },
                    {
                      label: 'PP100',
                      value: 'PP100',
                    },
                  ]}
                  onChange={handleChange}
                  value={pet?.confirmCrateSize}
                  error={petsErrors?.[idx]?.confirmCrateSize}
                  onBlur={handleBlur}
                  touched={touched.pets?.[idx]?.confirmCrateSize}
                  isErrorVisible={isErrorsVisible}
                  innerRef={isFieldInFocus?.(`pets.${idx}.confirmCrateSize`)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Input
                  label="Microchip Number"
                  name={`pets[${idx}].microchipNumber`}
                  onChange={handleChange}
                  placeholder="e.g. 123456789012345"
                  value={pet?.microchipNumber}
                  error={petsErrors?.[idx]?.microchipNumber}
                  onBlur={handleBlur}
                  touched={touched.pets?.[idx]?.microchipNumber}
                  isErrorVisible={isErrorsVisible}
                  innerRef={isFieldInFocus?.(`pets.${idx}.microchipNumber`)}
                />
              </Grid>
              <Grid item xs={12}>
                <Radio
                  label="Is your pet entering a boarding kennel?"
                  name={`pets[${idx}].isEnteringABoardingKennel`}
                  menuItems={radioToggleItems}
                  onChange={handleChange}
                  value={pet?.isEnteringABoardingKennel}
                  error={String(petsErrors?.[idx]?.isEnteringABoardingKennel)}
                  isErrorVisible={isErrorsVisible}
                  innerRef={isFieldInFocus?.(
                    `pets.${idx}.isEnteringABoardingKennel`,
                  )}
                />
              </Grid>

              {isValueEqual(`pets[${idx}].isEnteringABoardingKennel`, true) && (
                <>
                  <Grid item xs={12} md={6}>
                    <DatePicker
                      label="Boarding Start Date"
                      name={`pets[${idx}].boarding.boardingDate.start`}
                      onChange={handleChange}
                      value={pet?.boarding?.boardingDate?.start}
                      error={petsErrors?.[idx]?.boarding?.boardingDate?.start}
                      isErrorVisible={isErrorsVisible}
                      onBlur={handleBlur}
                      touched={
                        touched.pets?.[idx]?.boarding?.boardingDate?.start
                      }
                      innerRef={isFieldInFocus?.(
                        `pets.${idx}.boarding.boardingDate.start`,
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <DatePicker
                      label="Boarding End Date"
                      name={`pets[${idx}].boarding.boardingDate.end`}
                      onChange={handleChange}
                      value={pet?.boarding?.boardingDate?.end}
                      error={petsErrors?.[idx]?.boarding?.boardingDate?.end}
                      isErrorVisible={isErrorsVisible}
                      onBlur={handleBlur}
                      touched={touched.pets?.[idx]?.boarding?.boardingDate?.end}
                      innerRef={isFieldInFocus?.(
                        `pets.${idx}.boarding.boardingDate.end`,
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      label="Is your pet social with other animals?"
                      name={`pets[${idx}].boarding.isSocialWithOther`}
                      menuItems={[
                        {
                          label: 'Yes',
                          value: 'yes',
                        },
                        {
                          label: 'No',
                          value: 'no',
                        },
                      ]}
                      onChange={handleChange}
                      value={pet?.boarding?.isSocialWithOther}
                      error={petsErrors?.[idx]?.boarding?.isSocialWithOther}
                      onBlur={handleBlur}
                      touched={touched.pets?.[idx]?.boarding?.isSocialWithOther}
                      isErrorVisible={isErrorsVisible}
                      innerRef={isFieldInFocus?.(
                        `pets.${idx}.boarding.isSocialWithOther`,
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      label="Vaccination Status"
                      name={`pets[${idx}].boarding.vaccinationStatus`}
                      menuItems={[
                        {
                          label: 'Yes',
                          value: 'yes',
                        },
                        {
                          label: 'No',
                          value: 'no',
                        },
                      ]}
                      onChange={handleChange}
                      value={pet?.boarding?.vaccinationStatus}
                      error={petsErrors?.[idx]?.boarding?.vaccinationStatus}
                      onBlur={handleBlur}
                      touched={touched.pets?.[idx]?.boarding?.vaccinationStatus}
                      isErrorVisible={isErrorsVisible}
                      innerRef={isFieldInFocus?.(
                        `pets.${idx}.boarding.vaccinationStatus`,
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid item xs={12} md={6} className={dropzoneWrapper}>
                      <Dropzone
                        label="Vaccination Document"
                        text="Upload Certificate"
                        acceptTypeText="pdf, doc"
                        name={`pets[${idx}].boarding.vaccinationDocument`}
                        setFieldValue={setFieldValue}
                        maxFiles={1}
                        maxSize={10000000}
                        accept={{
                          'application/pdf': [],
                          'application/msword': [],
                        }}
                        value={
                          pet?.boarding?.vaccinationDocument as FileValue[]
                        }
                        error={String(
                          petsErrors?.[idx]?.boarding?.vaccinationDocument ??
                            '',
                        )}
                        required
                        isErrorVisible={isErrorsVisible}
                        fetchAwsFiles={(newFiles) =>
                          fetchAwsFilesParamsBooking(newFiles, webQuoteId)
                        }
                      />
                    </Grid>
                  </Grid>
                </>
              )}

              <Grid item xs={12}>
                <Radio
                  label="Does your pet have any pre-existing medicinal conditions or is your pet currently taking medication?"
                  name={`pets[${idx}].hasMedicinalConditions`}
                  onChange={handleChange}
                  menuItems={radioToggleItems}
                  value={pet?.hasMedicinalConditions}
                  error={String(petsErrors?.[idx]?.hasMedicinalConditions)}
                  isErrorVisible={isErrorsVisible}
                  innerRef={isFieldInFocus?.(
                    `pets.${idx}.hasMedicinalConditions`,
                  )}
                />
              </Grid>

              {isValueEqual(`pets[${idx}].hasMedicinalConditions`, true) && (
                <>
                  <Grid item xs={12} md={6}>
                    <Input
                      label="List Medical Conditions"
                      name={`pets[${idx}].medical.listMedicalConditions`}
                      placeholder="List your pet's medical conditions here"
                      multiline={true}
                      rows={3}
                      onChange={handleChange}
                      value={pet?.medical?.listMedicalConditions}
                      error={petsErrors?.[idx]?.medical?.listMedicalConditions}
                      onBlur={handleBlur}
                      touched={
                        touched.pets?.[idx]?.medical?.listMedicalConditions
                      }
                      isErrorVisible={isErrorsVisible}
                      innerRef={isFieldInFocus?.(
                        `pets.${idx}.medical.listMedicalConditions`,
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Input
                      label="List Medications"
                      name={`pets[${idx}].medical.listMedications`}
                      placeholder="List the medications your pet needs here"
                      multiline={true}
                      rows={3}
                      onChange={handleChange}
                      value={pet?.medical?.listMedications}
                      error={petsErrors?.[idx]?.medical?.listMedications}
                      onBlur={handleBlur}
                      touched={touched.pets?.[idx]?.medical?.listMedications}
                      isErrorVisible={isErrorsVisible}
                      innerRef={isFieldInFocus?.(
                        `pets.${idx}.medical.listMedications`,
                      )}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </React.Fragment>
      ))}
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Button
          className={whiteButton}
          variant="outlined"
          size="large"
          onClick={() => addPet()}
        >
          Add another pet
        </Button>
      </Grid>
    </Grid>
  );
};
