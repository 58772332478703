import React, { useMemo } from 'react';

import { gql, useQuery } from '@apollo/client';
import {
  Autocomplete,
  Input,
  Radio,
} from '@dogtainers/dgt-blocks/src/components/form';
import { Grid, Typography } from '@material-ui/core';

import { MenuItem, PageFormProps } from '../booking.types';
import useStyles from './styles';

export const BookingPage1: React.FC<PageFormProps> = ({
  values: { quoteId, consultantId, contact },
  handleChange,
  errors: {
    quoteId: quoteIdError,
    consultantId: consultantIdError,
    contact: contactErrors,
  },
  handleBlur,
  touched,
  isErrorsVisible,
  isFieldInFocus,
  children,
}) => {
  const query = gql`
    query BookingPage1 {
      consultantDetails {
        listConsultantDetails {
          data {
            name
            id
          }
        }
      }
    }
  `;

  const consultantQueryData = useQuery(query, {
    onError: (e) => console.error(e.message),
  });

  const consultantsItems = useMemo(
    () =>
      consultantQueryData?.data?.consultantDetails?.listConsultantDetails?.data?.map(
        ({ id, name }: { id: string; name: string }) => ({
          value: id,
          label: name,
        }),
      ) || [],
    [consultantQueryData],
  ) as MenuItem;

  const { pageTitle, pageSubTitle } = useStyles();

  return (
    <Grid container justifyContent="center" spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h4" className={pageTitle}>
          Booking Form
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" className={pageSubTitle}>
          Customer and Travel Information
        </Typography>
      </Grid>
      {children}
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Input
              label="Quote Number"
              name="quoteId"
              placeholder="Enter the number attached to your quote"
              onChange={handleChange}
              value={quoteId}
              error={String(quoteIdError ?? '')}
              onBlur={handleBlur}
              touched={touched.quoteId}
              isErrorVisible={isErrorsVisible}
              innerRef={isFieldInFocus?.('quoteId')}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              label="Booking Consultant"
              name="consultantId"
              onChange={handleChange}
              menuItems={consultantsItems}
              value={consultantId}
              error={String(consultantIdError ?? '')}
              isErrorVisible={isErrorsVisible}
              onBlur={handleBlur}
              touched={touched.consultantId}
              innerRef={isFieldInFocus?.('consultantId')}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              label="First Name"
              name="contact.nameFirst"
              placeholder="Name here"
              onChange={handleChange}
              value={contact?.nameFirst}
              error={contactErrors?.nameFirst}
              onBlur={handleBlur}
              touched={touched.contact?.nameFirst}
              isErrorVisible={isErrorsVisible}
              innerRef={isFieldInFocus?.('contact.nameFirst')}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              label="Last Name"
              name="contact.nameLast"
              placeholder="Last name here"
              onChange={handleChange}
              value={contact?.nameLast}
              error={contactErrors?.nameLast}
              onBlur={handleBlur}
              touched={touched.contact?.nameLast}
              isErrorVisible={isErrorsVisible}
              innerRef={isFieldInFocus?.('contact.nameLast')}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              label="Phone Number"
              name="contact.phone"
              placeholder="e.g. +61 888 888 888 "
              onChange={handleChange}
              value={contact?.phone}
              error={contactErrors?.phone}
              onBlur={handleBlur}
              touched={touched.contact?.phone}
              isErrorVisible={isErrorsVisible}
              innerRef={isFieldInFocus?.('contact.phone')}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              label="Email"
              name="contact.email"
              onChange={handleChange}
              value={contact?.email}
              error={contactErrors?.email}
              onBlur={handleBlur}
              touched={touched.contact?.email}
              isErrorVisible={isErrorsVisible}
              innerRef={isFieldInFocus?.('contact.email')}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Radio
              label="Are you a member of Defence?"
              name="contact.isDefenceMember"
              value={contact?.isDefenceMember}
              error={String(contactErrors?.isDefenceMember)}
              onChange={handleChange}
              menuItems={[
                {
                  label: 'Yes',
                  value: true,
                },
                {
                  label: 'No',
                  value: false,
                },
              ]}
              isErrorVisible={isErrorsVisible}
              innerRef={isFieldInFocus?.('contact.isDefenceMember')}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
